.login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__container {
    height: 100vh;
    width: 100%;
    padding-top: 4vw;
    border-radius: 5px;
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

.login__textBox {
    padding: 8px;
    font-size: 16px;
    margin-bottom: 10px;
}

.login__btn {
    padding: 12px;
    font-size: 22px;
    margin-bottom: 10px;
    color: white;
    background-color: black;
}