@import '../node_modules/antd/dist/antd.css';


.App {
  text-align: center;
}

h1 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(16px + 1.4vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 26.4px;
}

h2 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(15px + 1.4vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
}

h3 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(14px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 20px;
}

h4 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(13px + .75vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 700;
  line-height: 20px;
}

h5 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(11px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20px;
}

h6 {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(8px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: normal;
}

p {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(8px + 1.1vmin);
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Header */
.header-container {
  max-width: 200px;
  max-height: 71px;
}

/* registration */
.user-type-container {
  border: 2px solid #acacac;
  border-radius: 8px;
  padding: 12px;
}

.user-type-button {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  font-size: calc(9px + .65vmin);
  background-color: #20386499;
  border-width: 0px;
  color: #fff;
  border-radius: 8px;
  padding: .75em;
  margin: .55em;
  cursor: pointer;
}

/* Create Claim Form */
.create-claim-form {
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}